import React, { useState, useEffect, Component } from 'react';
import './App.css';
import YouTube from 'react-youtube';
import axios from 'axios';

const BACKEND_URL = "https://c6c92b17-66cb-4a61-bb80-db22ee79c067.mock.pstmn.io"
const PLAYLIST_NAME = "nigo"

function ChangeButton(props) {
    const changePrevVideo = function changePrevVideo() {
        if (props.Value <= 0) {
            props.setValue(0);
        } else {
            props.setValue(props.Value - 1);
        }
    };
    const changeNextVideo = function changeNextVideo() {
        if (props.Value >= 2) {
            props.setValue(2);
        } else {
            props.setValue(props.Value + 1);
        }
    };
    return (
        <div>
            <button onClick={changePrevVideo}>이전으로</button>
            <button onClick={changeNextVideo}>다음으로</button>
        </div>
    );
}
function Player() {
    const [videoIdIndex, setVideoIdIndex] = useState(0);
    const [videoDatas, setVideoDatas] = useState(null);
	const [loading, setLoading] = useState(false);

    const changeNextVideo = function NextVideo() {
        if (videoIdIndex >= videoDatas.length) {
            setVideoIdIndex(videoDatas.length);
        } else {
            setVideoIdIndex(videoIdIndex + 1);
        }
    };

    const fetchDatas = async () => {
        try {
            const PLAYLIST_NAME = window.location.pathname;
            
            const response = await axios.get(BACKEND_URL + '/lists' + PLAYLIST_NAME);
        	setLoading(true);
      		setVideoDatas(response.data);
        } catch (e) {
            // alert(JSON.stringify(e));
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchDatas();
    }, []);
  	
    // const Vars = {playerVars: {
    //     autoplay: 1,    
    // }}

    const Vars = {playerVars: {
        
    }}
    
  	if (loading) return <div>로딩중..</div>; 
    if (!videoDatas) return null;

    return (
        <div className="player">
            플레이리스트 이름 : {window.location.pathname}
            <br></br>
            플레이 중인 곡 이름 : {videoDatas.videos.data[videoIdIndex].name}
            <br></br>
            <YouTube videoId={videoDatas.videos.data[videoIdIndex].videoId} opts={Vars} />
            <ChangeButton Value={videoIdIndex} setValue={setVideoIdIndex} />
        </div>
    );
}

class App extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="App">
                <Player></Player>
            </div>
        );
    }
}

export default App;